export default class CategoryCreateForm {
  constructor(data) {
    this.name = data?.name
    this.image = data?.image
    this.isActive = data?.isActive
    this.itemTypePrefix = data?.itemTypePrefix
    this.sortWeight = data?.sortWeight ?? 0
    this.useForNewOrderItem = data?.useForNewOrderItem
  }
  static create(data) {
    return new CategoryCreateForm(data)
  }
}
